import styled from "styled-components"
/*
import { Headline } from "../Headline"
*/

export const Headline = styled.div`
width: 100%;
padding-left: 5%;
background-color: #EEEEEE;
position: relative;
display:flex;
flex-direction: row;
align-content:baseline;
justify-self: center;

.icon {
align-self: center;
width: 35px;
margin: 6px 0;
}


.placetitle{
  display: flex;
align-self :center ;
flex-wrap: nowrap;
width: auto;

}
@media (max-width:500px){ /*************/ 

}
`