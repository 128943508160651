import styled from "styled-components"

export const Subtitle = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 24px;
  line-height: 30px;
  margin: 20px 0;
  text-align: ${props => props.align || "left"};
  @media (max-width: 1400px) {
    font-size: 20px;

  }
  @media (max-width: 1200px) {
    font-size: 18px;
    margin:  0;

    
  }
`
