import styled from "styled-components"

export const TitleHero = styled.h1`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 3rem;
  //line-height: 3.75rem;
  margin-bottom: 0.5rem;

 
  @media (max-width: 1440px) {
    font-size: 32px;
    margin-top: 2em;
  }
  @media (max-width: 1000px) {
    font-size: 25px;
    margin-top: 0;

  }
`
