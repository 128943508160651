import * as React from "react"
import Carousel from "react-multi-carousel"
import styled from "styled-components"

import { Title } from "../Title"
import { Cards } from "../SliderCourses/Cards"
import { Headline } from "../Headline"

import "react-multi-carousel/lib/styles.css"


const ContainerSlider = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 2em;
`
const SecondBlockChild = styled.div`
width: 100%;
position: relative;
margin-top: 40px;
padding: 0 1.5em 0 5em;

.carrusel {
  .react-multiple-carousel__arrow {
    z-index: 1;
  }
  .react-multi-carousel-item {
  }
  .react-multiple-carousel__arrow {/*new */
    background-color: none;
  }
  .react-multiple-carousel__arrow::before {
    font-size: 30px;
    font-weight: 900;
    color: #49b3cb;
  }
  .react-multiple-carousel__arrow--left {
    left: -1em;
  }
  .react-multiple-carousel__arrow--right {
    right: -1em;
  }
  .react-multiple-carousel__arrow::before {/*new */
    color:#0044B0;
    padding: 0 18px;
  }
  .react-multiple-carousel__arrow {
    background:none;
  }
  .react-multi-carousel-item {/*new */
  padding: 0 10px; 
  }

  @media (max-width:500px){ /*************/ 
  width: 100%
}
}

  .cards {
    height: 481px;
    width: 290px;
    margin: 20;
    border-radius: 4px;
    @media (max-width:500px){ /*************/ 

}
  }
  @media (max-width:500px){ /*************/ 
  padding: 0 3em;

}

`
const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 600 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
}


export const SliderCourses = ({ data }) => {
  var array = []
  for (var i in data) {
    if (data.hasOwnProperty(i)) {
     
      //data[i].id = i
      array.push(data[i])
    }
  }

  var fieldToSort = "order"
  array.sort(function (a, b) {
    return a[fieldToSort] - b[fieldToSort]
   
  })



  
  return (
    <>
       <ContainerSlider>

         <Headline>
          <div className="icon"><img src="images\viñeta.svg"/></div>
          <div className="placetitle"><Title>Cursos disponibles</Title></div>
          </Headline>

  <div style={{display: 'flex', justifyContent: 'center'}}>
        <SecondBlockChild>
          <Carousel
            className="carrusel"
            responsive={responsive}
            infinite={true}
            /*  autoPlay={true} */
            autoPlaySpeed={3000}
            keyBoardControl={true}
          >
            {array.map(element => (
              <Cards
                title={element.title}
                img={element.slider_image}
                id={element.id}
                init_date={element.init_date}
              />
            ))}
          </Carousel>

        </SecondBlockChild>
        </div>
      </ContainerSlider>

    </>
  )
}
