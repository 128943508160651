import React, { useState, useEffect } from "react"
import { TitleHero } from "../TitleHero"
import { Subtitle } from "../Subtitle"

import { Button01 } from "../Button01"
import styled from "styled-components"
import { Link } from "gatsby"
import { getGeneralInfo} from "../../providers"



const Container = styled.div`
  padding: 0 0 0 3.125em;
  display: flex;
  justify-content: space-around;

  .blue2{
    color:#0044B0;
  }

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    margin-top: 2em;
    width: 100%;
    background-image: url("/images/hero/herobg.png");
    margin-bottom: 6rem;
    padding: 0 0 0 0;
  }

  @media (max-width: 500px) {
    flex-direction: column-reverse;
    margin-top: 3em;
    margin-bottom: 3rem;
    width: 98%;
    background-image: url("/images/hero/herobg.png");
}
`
const Imagesoftwares = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 30px;
  object-fit: cover;
  margin-bottom: 30px;
  @media (max-width: 1400px) {
    max-width: 250px;
  }
  @media (max-width: 1200px) {
    margin-top: 1em;

  }
  @media (max-width: 500px) {
    max-width: 200px;
    
  }
`
const ContainerInfo = styled.div`
  width: 40%;
  margin: auto;
  .button01 {
    width: 100%;
    text-align: start;
  }
  @media (max-width: 900px) {
    width: 100%;
    padding:0px 20px;
    margin: 0px;
  }
`
const ContainerVideo = styled.div`
  width: 60%;
  position: relative;
  .frame-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */  
    padding-top: 25px;
    width: 300%; /* enlarge beyond browser width */
    left: -100%; /* center */
}

.frame-container iframe {
    position: absolute; 
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
}

.wrapper {
   overflow: hidden;
   max-width: 100%;
}


  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    background: linear-gradient(
        90deg,
        #FFFFFF 1.13%,
        rgba(255, 255, 255, 0.91648) 8%,
        rgba(255, 255, 255, 0.652013) 21.76%,
        rgba(255, 255, 255, 0) 39.67%
      ),
      url(revit-min-1.jpg);
  }
  video {
    width: 100%;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding-bottom: 2rem;
    .overlay {
    display:none;
  }
    video {
      width:100%;
    }
  }
`

export const Hero1 = ({ title, subtitle, buttonText, video, icons }) => {

  const [info, setInfo] = useState({})

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const _info = await getGeneralInfo()
     
        setInfo(_info)
      } catch (error) {
        console.log(error)
    
      }
    }
    fetchCourse()
  }, [])

  return (
    <>
      <Container>
        <ContainerInfo>
          <TitleHero className="blue2">{title}</TitleHero>
          <Subtitle className="blue2">{subtitle}</Subtitle>
          <Imagesoftwares src={icons} />

          <div className="button01">
          <a  href={info[0]?.whatsapp_link} target="_blank">
              <Button01 type="large">{buttonText}</Button01>
            </a>
          </div>
        </ContainerInfo>
        <ContainerVideo>
          <div className="overlay"></div>
          {/*<video width="886" autoPlay="autoplay" loop muted>
            <source src="video/home-herocrop.mp4" type="video/mp4" />
          </video>*/}

<div class="wrapper">
   <div class="frame-container">
   <iframe 
    width="560" 
    height="315" 
    src={`https://www.youtube.com/embed/${video}?&playlist=${video}&loop=1&rel=0&controls=1&autoplay=1&mute=1&start=0`} 
    title='YouTube video player' 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowfullscreen>
  </iframe>   </div>
</div>

          {/* <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/wxxSxJFHtJ4?controls=0autoplay=1"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe> */}
        </ContainerVideo>
      </Container>
    </>
  )
}
