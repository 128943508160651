import * as React from "react"
import styled from "styled-components"
import { Title } from "../Title"
import { ItemCourse } from "./ItemCourse"
import { Headline } from "../Headline"

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 2em;
`

const SecondBlock=styled.div`
width: 100% ;
background: #EBECF0;
margin-top: 1.5em;
display: flex;
justify-content: center;

`
const ContainerInfo = styled.div`
  max-width: 1440px;
  height: auto;
  background: #EBECF0;
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width:500px){ /*************/ 
  width: 100%;
  padding: 0px;
}
`

const Benefits = ({ data }) => {
 
  return (
    <Container>
    
         <Headline>
          <div className="icon"><img src="images\viñeta.svg"/></div>
          <div className="placetitle"><Title>Beneficios de nuestros cursos</Title></div>
          </Headline>
       
        
      <SecondBlock>
      <ContainerInfo>
        {data?.map((element, index) => (
          <ItemCourse key={index} {...element} />
        ))}
      </ContainerInfo>
      </SecondBlock>
    </Container>
  )
}

export default Benefits
