import styled from "styled-components"

export const TitleCard = styled.div`
  font-family: "Montserrat", sans-serif;
  font-feature-settings: 'clig' off, 'liga' off;

  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2rem;

 /* @media (max-width: 1300px) {
    font-size: 20px;
  }
  @media (min-width: 1700px) {
  }*/
`
