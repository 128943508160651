import * as React from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 28%;
  display: flex;
  justify-content: space-between;
  margin: 20px;
  align-items: center;
  
  div {
    width: 100px;
    height:100px ;
    display:flex;
    border-radius: 50%;
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    margin-right: 1em;
    aspect-ratio: 1;
    object-fit: scale-down;
    padding: 10px 10px;
    align-items: center;
    justify-content: center;
    img{
/*scale:0.65 ;*/

    width: 100%;
    height: 100%;
    border-radius: 10%;
    padding: 10px;

    }
  }
  p {
    width: calc(100% - 100px);
    text-align: left;
    position: relative;
    color: #292929;
    font-size: 15px;

  }

  @media (max-width: 1200px) {
    p {
      font-size: 14px;
    }
  }

  @media (max-width: 950px) {
    width: 100%;
  }
`

export const ItemCourse = ({ image, content }) => {
 

  return (
    <Container>
      <div>
      <img src={image} alt="cursos de autocad certificados al mejor precio del mercado" />
        </div> 
      <p>{content}</p>

    </Container>
  )
}
