import React from "react"
import styled from "styled-components"
import { Title } from "../Title"
import { Button02 } from "../Button02"
import { Link } from "gatsby"
import { Headline } from "../Headline"

const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 3.4375rem;
  align-items: center;

`

const SecondBlock=styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
margin-top: 2.5em;
justify-content: center;

@media (max-width:500px){ /*************/ 
margin-top: 2em;

}
`

const ContentInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  

  @media (min-width: 2400px) {
  max-width: 1440px;
}
  @media (max-width: 900px) {
    margin: 0;
    flex-direction: column;
    gap: 0;
   
    .info-content {
      width: 100%;
      margin: 0px;
    }

    .info-content {
      margin: 0;
      p {
        margin: 0px;
      }
    }
  }
`
const PartOne = styled.div`
width: 50%;
flex-direction: column;
justify-content: flex-start;
padding: 0 4% 0 8%;
position: relative;
display: flex;

  .title{
    color: #292929;
    font-style: italic;
    font-size: 22px;
    font-weight: 700;
    margin: 2em 0 1em 0;
    }
    .p1 {
      
      text-align: left;
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      line-height: 160%;
      color: #302f2f;
      
    }
    .texquote{
      margin-top: 1em;
      display: flex;
      position: relative;
      flex-wrap: nowrap;
      justify-content: baseline;
      .quotes{
      display: flex;
      position: relative;
      color: #686868;
      font-size: 50px;
      font-style: italic;
      line-height: 150%;
      font-family: "Montserrat";
      
    }
    .p2{
      display: inline-block;
      position: relative;
      color:#929292;
      font-size: 20px;
      font-style: italic;
      line-height: 150%;
      font-family: "Montserrat";
      margin: 0.5em 0 1em 0;

      strong{
        color:#929292;
        font-size: 38px;
        font-weight: 500;
      }
    }
    }
    
    .button {
      display: flex;
      justify-content: flex-start;
      margin-top: 1em;
    }
  
    @media (max-width: 900px) {
      width: 100%;
    }
    @media (max-width: 600px) {
      width: 100%;
      .p1 {
        font-size: 15px;
      }
      .texquote {
        .p2{
        font-size: 16px; 
        strong{
        font-size: 17px;   
        } 
    }}
    .button{
      justify-content: center;

    }
    }
    @media (max-width: 480px){
      padding: 0 7%;
      .title{
        font-size: 16px;
      }
      .p1{
        font-size: 14px;
        text-align: justify;

      }
    }
`

const PartTwo = styled.div`
width: 50%;
position: relative;
justify-content: right;
display: flex;
.imgbox {
  object-fit: scale-down;
  width: 100%;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-self: center;
    justify-content: center;
    p{
      display: flex;
      justify-self:flex-start;
      margin-top: 1em;
      font-style: italic;
    }
    img{
      max-height: 470px;
      object-fit: cover;

    }
  }

  @media (max-width: 900px) {
      width: 100%;
      justify-content: center;
      margin-top: 24px ;
      .imgbox {
        width: 100%;
        margin: 0;
      }
    }
`
const ThirdBlock = styled.div`
max-width: 100%;
background-color: #0044B0;
display: flex;
justify-content: center;
padding: 10px 0;
margin: 4em 0;
position: relative;
  .images {
      //max-width: 1440px;
      width: 100%;
      max-width: 1440px;
      height: auto ;
      background-color: #0044B0;
      display: flex;
      flex-wrap: wrap;
      //justify-content: space-around;
      justify-content: space-evenly;
      gap:0px;
      a{
        display: flex;
        justify-content: center;
      }
      img {
        object-fit: scale-down;
        //max-width: 60%;
        max-width: 240px;
        //height: 100%;
        width: 100%;
        aspect-ratio: auto;

      }}

      @media (max-width: 1350px) {
        .images{
          img {
            max-width: 160px;
          }
        }
      }
      @media (max-width: 900px) {
        .images{
          gap:24px;
          img {
            max-width: 160px;
            margin: 0.5em ;
          }
        }
      }

      @media (max-width: 600px) {
        margin: 3em 0 0 0;

        .images{
          gap:24px;
          height:auto ;
          padding: 1em 0;
          img{
            max-width: 160px;
          margin: 1em 0;
          }
        }
      }
`
const FourthBlock = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 3em;
`
//TODO hacer funcion de incrementar numeros
const NumericalCounter = styled.div`
  width: 1440px;
  padding: 0 8em 0 1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .content-data {
    width: 250px;
    text-align: center;
    .number,
    .text {
      width: 325px;
    }
    .number {
      font-family: "Montserrat";
      color: #292929;
      font-weight: 800;
      font-size: 90px;
      line-height: 160%;
      text-align: center;
    }
    .text {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 22px;
      line-height: 150%;
      text-align: center;
    }
  }

  @media (max-width: 1150px) {
    .content-data {
      .number {
        font-size: 60px;
      }
      .text {
        font-size: 19px;
      }
    }
  }

  @media (max-width: 900px) {
    //margin-top: 30px;
    padding: 0 1em 0 1em;
    justify-content: center;
    .content-data {
    width: 100%;
    text-align: center;
    .number,
    .text {
      width: 100%;
    }
    .number {
      font-family: "Montserrat";
      color: #292929;
      font-weight: 800;
      font-size: 56px;
      line-height: 160%;
      text-align: center;
    }
    .text {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 22px;
      line-height: 150%;
      text-align: center;
    }
  }

  }

`
export const ChooseUs = ({
  title,
  imageUs,
  certificationImages,
  chooseUsContent,
  counts,
  phrase,
  Imgdescription,

}) => {
  const modifiedCertificationImages = certificationImages?.map(element => {
    const imageUrlParts = element.image.split("/");
    const imageNameWithExtension = imageUrlParts[imageUrlParts.length - 1];
    
    const imageName = imageNameWithExtension.substring(0,imageNameWithExtension.length-4);
  
    const imageNameWithHttps = "https://" + imageName;
    return {...element , url: imageNameWithHttps.replace("_", "/")};
  });
  
  return (
    <Container>
  <Headline>
          <div className="icon"><img src="images\viñeta.svg"/></div>
          <div className="placetitle"><Title>Capacítate con nosotros</Title></div>
  </Headline>


<SecondBlock>
  <ContentInfo>
    <PartOne>

        <div className="title">{title}</div>

        <div className="p1">{chooseUsContent}</div>
        <div className="texquote">
        {/*<div className="quotes">"</div>*/}
        <div className="p2">
          {/*{chooseUsContent2}*/}<strong>“ </strong>{phrase}<strong> ”</strong></div>
        {/*<div className="quotes">"</div>❝❞*/}
        </div>
        <div className="button">
          <Link to="/nosotros">
            <Button02>Conoce más</Button02>
          </Link>
        </div>

    </PartOne>
    <PartTwo>
      <div className="imgbox">
          <img src={imageUs} alt="Cursos revit certificados por ITCadPerú Autodesk Training Center"/>
          <p>{Imgdescription}.</p>
        </div>
        
    </PartTwo>
  </ContentInfo>
</SecondBlock>

<ThirdBlock>
    <div className="images">
    {modifiedCertificationImages?.map((element, index) => (
<a href={element.url} target="_blank"> 
      <img key={index} src={element.image} alt=" Revit, cursos especializados para estudiantes de todas las edades. "/>
      </a>
         ))}
    </div>
</ThirdBlock>

<FourthBlock>
      <NumericalCounter>
        {counts?.map((element, index) => (
          <div key={index} className="content-data">
            <div className="number  sky-blue">{element.number}</div>
            <div className="text black">
              <span>{element.text}</span>
            </div>
          </div>
        ))}

        {/* <div className="content-data">
          {data.counts.map(element => (
            <Count key={element.id} data={element} />
          ))}
        </div> */}
      </NumericalCounter>
</FourthBlock>

    </Container>
  )
}
