import * as React from "react"
import styled from "styled-components"
import Carousel from "react-multi-carousel"
import { useState } from "react"

import { Title } from "../Title"
import { Paragraph } from "../Paragraph"

import "react-multi-carousel/lib/styles.css"
import { Headline } from "../Headline"

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1300 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1300, min: 900 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 900, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
}

const ContainerSlider = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 2em;
  align-items: center;

`

const SecondBlock=styled.div`
width: 100%;
//max-width: 1440px;
display: flex;
justify-content: center;
margin-top: 5em;
flex-direction: row;
.titleblock2{
    background-color: #FFFFFF;
    //margin-top: 1em;
    color: #0044B0;
    justify-self: flex-start;
  }
  @media (min-width: 2400px) {
  max-width: 1440px;
}
  @media (max-width:900px){ /*************/ 
  width: 100%;
  margin-left: 0px;
  padding: 0 20px ;
  flex-direction: column;
}



`
const ContentTestimonios = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-self: baseline;
  align-content: space-between;
  left: 0;

  .imgbox{
    width: 50%;
  }

  @media (max-width: 900px) {
    //width: 100%;
    display: flex;
   // flex-direction: column;
   width: 100%;
  }
  .imgbox{
    object-fit: scale-down;
    width: 100%;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-self: right;
    p{
      display: flex;
      justify-self:center;
      margin-top: 1em;
      font-style: italic;
    }
  }

  @media (max-width: 500px) {
    .imgbox{
    object-fit: scale-down;
    width: 100%;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    justify-self: right;
  }
  }

`
const ContentCarrusel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  //margin-top: 2em;

  padding: 0 10%;
  /*margin-top: 1.875rem;
  margin-bottom: 10.625rem;*/

  .carrusel {
    .react-multiple-carousel__arrow {
    z-index: 1;
  }
    .react-multi-carousel-item {
      width: 100%;
      padding: 0 25px;
      display: flex;
      justify-content: center;
    }
    .react-multiple-carousel__arrow {
      background: none;
    }
    .react-multiple-carousel__arrow::before {
      font-size: 24px;
      font-weight: 900;
      color: #0044B0;
    }
    .react-multiple-carousel__arrow--right {
      right: -10px;
    }
    .react-multiple-carousel__arrow--left {
      left: -10px;
    }
    .react-multi-carousel-track{
      align-items:center;
    }
.cqsxfz{
  display: flex;
  justify-content: center;
  flex-direction: column;
}


  }
  .cards {
    width: 70%;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 160%;
  }

.outside{
  position: absolute;
  left: 500px;
}

@media (min-width: 2400px) {
  padding: 0 0 0 0;
}
  @media (max-width: 900px) {
    width: 100%;
    padding: 0 10%;
    margin-top: 0;

  }
  @media (max-width: 500px) {
    padding: 0 0 0 0;
    margin-top: 0;

    .carrusel {
      .cqsxfz{
        margin: 0;
        font-size: 14px;
      }
    }
  }

`
const ParagraphStyled = styled(Paragraph)`
  line-height: 160%;
  text-align: center;
`

const ThirdBlock=styled.div`
width: 100%;
display: flex;
position: relative;
justify-content: center;
margin-bottom: 4em;



`
const ThirdBlockChild=styled.div`
max-width: 1440px;
width: 100%;
//margin-left: 90px;
display: flex;
flex-direction: column;
justify-content: flex-start;
position: relative;


.titleblock3{
    background-color: #FFFFFF;
    margin-top: 2em;
    margin-bottom: 0.5em;
    color: #0044B0;
    display: flex;
    justify-self: flex-start;
    position: relative;

  }
  .descriptionblock3{
    position: relative;
    color: #292929;
    font-size: 22px;
    margin-bottom: 1.5em;
  }

  @media (max-width:1440px){ /*************/ 
  width: 100%;
  margin-left: 0px;
  padding: 0 5% ;
  .descriptionblock3{
    font-size: 14px;

  }
}
`
const ImageEvents = styled.img`
width:100%;
max-width:100%;
@media (max-width:500px) {
 max-width : 100% ;
}
`
const Testimonyimage = styled.div`
width: 50% ;
display:flex ;
align-items: center;
justify-content:flex-end;

@media (max-width:900px) {
width : 100% ;
}
`
const Testimonyimageinner = styled.div`
display:flex; 
flex-direction: column;
width:100%;
img{
  //max-height: 470px;
  object-fit: cover;
  aspect-ratio: 2/1;
}
@media (max-width:500px) {
 width : 100% ;
}
`
export const Testimonys = ({ data,main }) => {
  const [nowIndex, setNowIndex] = useState(0);

  const changeImage = (nowSlide, manualChange) => {


    if (manualChange) {
      
      setNowIndex(manualChange.currentSlide -2);


    }
  };

  return (
    <ContainerSlider>

     <Headline>
      <div className="icon"><img src="images\viñeta.svg"/></div>
      <div className="placetitle"><Title>Experiencias ITCAD</Title></div>
      </Headline>


    <SecondBlock>
 
        <ContentTestimonios>
        <ContentCarrusel>
          <Title className="titleblock2">Testimonios</Title>
        
          <Carousel
            className="carrusel"
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            keyBoardControl={true}
            afterChange={changeImage}
          >
            {data?.map((element, index) => (
              <div key={index}>
              <ParagraphStyled>
                {element?.testimony}
                <br />
                <br />
                <b>
                  {element?.name} - {element?.ocupation}
                </b>
              </ParagraphStyled>

              </div>
            ))}
          </Carousel>       
        </ContentCarrusel>
     
      </ContentTestimonios>
      
      <Testimonyimage>
        <Testimonyimageinner>
        <img src={data[nowIndex]?.image} style={{width:'100%'}}/>
              <p style={{marginTop:'16px'}}>{data[nowIndex]?.image_description}</p>
        </Testimonyimageinner>
      </Testimonyimage>

    </SecondBlock>

    <ThirdBlock>
    <ThirdBlockChild>
    
    <Title className="titleblock3">Eventos y actividades</Title>
    <div className="descriptionblock3">{main?.events_section_description}</div>
    <a style={{width:'100%'}} href="/eventos">
    <ImageEvents src={main?.events_section_image}/>
    </a>
    </ThirdBlockChild>
    </ThirdBlock>
    </ContainerSlider>
  )
}
