import * as React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { dateFormat } from "../../utils"
import { TitleCard } from "../TitleCard"

import { convertLegacyProps } from "antd/lib/button/button"

const Container = styled.div`
  /*height: 481px;*/
  width: 100%;
  display: block;
  padding: 5%;
  

  .content-img {
    width: 100%;
    height: 53%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content-info {
    width: 100%;
    height: 47%;
    align-items: flex-start;
    margin-top: 1em;

    .text {
      font-size: 16px;
      line-height: 160%;
      color: #0044B0;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Montserrat;
font-style: normal;
font-weight: 400;

      }
    }

    .btn {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .content-title {

      align-items: flex-start;
      font-family: Montserrat;
      a{
        color: #302f2f;
      }
    }

`
const Title = styled(TitleCard)`
  line-height: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #292929;


`

export const Cards = ({ img, title, id ,init_date}) => {
  let linkCourse = ""
  switch (id) {
    case "0":
      linkCourse = "Especialista Modelador BIM"
      break
    case "1":
      linkCourse = "Especialista Infraestructuras Civil 3D"
      break
    case "2":
      linkCourse = "Autodesk Infraworks"
      break
    case "3":
      linkCourse = "Modelamiento y Visualización Arquitectónica"
      break
    case "4":
      linkCourse = "Autodesk Robot Structural"
      break
    case "5":
      linkCourse = "Autodesk Inventor"
      break
    case "6":
      linkCourse = "Autodesk Dynamo"
      break
    case "7":
      linkCourse = "Especialista CAD"
      break
    case "8":
      linkCourse = "Especialista Coordinador BIM"
      break
    default:
      linkCourse = "Especialista Modelador BIM"
  }

  

  return (
    <Container>
      <Link to={`/cursos/${id}`}>
      <div className="content-img">
        <img src={img} alt="Robots structural para estudiantes .Curso autodesk certificado por ITCadPerú Autodesk Training Center"/>
      </div>
      </Link>
      <div className="content-info">

        <div className="text">
          Inicio: {dateFormat(init_date)}
        </div>

        <div className="content-title">
        <Link to={`/cursos/${id}`}>
          <TitleCard>{title}</TitleCard>
          </Link>
        </div>


        {/*<div className="btn">
          <Link to={linkCourse}>
            <ButtonStyled>Mas información</ButtonStyled>
          </Link> 
          <Link to={`/cursos/${id}`}>                               (dato!)
            <ButtonStyled>Mas información</ButtonStyled>
          </Link>
        </div>*/}
      </div>
    </Container>
  )
}
