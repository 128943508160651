export const dateFormat = date => {
  try{
  let parts = date?.split("-")
  return `${parts[2]}/${parts[1]}/${parts[0]}`
  }
  catch{
    return ""
  }
  
}
