import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { getMainPage, getTestimonies, getCourses } from "../providers"

import { MainLayout } from "../components/Layouts/MainLayout"
import { Hero1 } from "../components/Hero1/index"
import { SliderCourses } from "../components/SliderCourses/index"
import { ChooseUs } from "../components/ChooseUs/index"
import { Testimonys } from "../components/Testimonys/index"
import Modal from 'react-modal';
import Benefits from "../components/Benefits"
import { Link } from "gatsby"

import "../assets/css/global.css"

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;

  margin: 0 auto;
`

const Modalstyled = styled(Modal)`
position:absolute ;
background-color: #FFFFFF;
/*height: 95%;
width: 40%;
min-height: 720px;
min-width: 500px;*/
left:50% ;
transform: translateX(-50%)  translateY(-50%);
top:50%;
display:flex ;
justify-content: center;
align-items: center;
flex-direction: column;
padding:15px ;

@media (max-width:670px){/*************/ 
  width: 90%;
  height: auto;
  
}

`
const Flyer = styled.img`
position:relative ;
width: 550px;
overflow-y: scroll; 
margin-bottom: 14px;
object-fit: contain;
/*@media (max-width:500px){
  width: 60%;
  
}*/
`
const GoCourses = styled(Link)`
&:hover {
    border: none;
    color: #FFFFFF;
    transform: scale(1.05);
    will-change: transform;
    cursor: pointer;
  }

`
const FlyerContainer= styled.div`
max-width: 650px;
position: relative;
display: flex;
height:100% ;
justify-content: flex-start;
flex-direction: row;
@media (max-width:1150px){/*************/ 
  width: 90%;
}


`
const Buttonsmall = styled.button`
position: absolute ;
background-color: #0044B0;
width: 30px;
height: 30px;
right:10px ;
top:10px;
border-radius: 25px;
border:none;    
font-weight: bold;
font-size: 0.7rem;
color: #FFFF;
/*margin: -106% 0 0 72%;*/

&:hover {
    border: none;
    color: #FFFFFF;
    transform: scale(1.05);
    will-change: transform;
    cursor: pointer;
  }

  @media (max-width:1150px){/*************/ 
  margin-left: 430px;

}

@media (max-width:970px){/*************/ 
margin-left: 390px;
}

@media (max-width:670px){/*************/ 
margin-left: 350px;
}

@media (max-width:570px){
  margin-left: 318px;
}
@media (max-width:510px){/*********/
margin-left: 230px;
width: 20px;
height: 20px;
font-size: 0.5rem;
}
`

const IndexPage = () => {
  const [mainPage, setMainPage] = useState([])
  const [courses, setCourses] = useState([])
  const [testimonies, setTestimonies] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const _info = await getMainPage()
        setMainPage(_info[0])
       

        setIsOpen(_info[0].enable_flyer)
        const _testimonies = await getTestimonies()
        setTestimonies(_testimonies)

        const _courses = await getCourses({ in_principal_page: true })
        setCourses(_courses)
        
      } catch (error) {
        console.log(error)
      }
    }
    fetchInfo()
  }, [])

  const customStyles = {
    content: {
      borderRadius:"16px",
      boxShadow: "-4px 20px 66px 12px rgba(125,125,125,0.3)",
      height: "95vh"
    },
    overlay: {zIndex: 200,backgroundColor:"rgba(255, 255, 255, 0.15)"}
   
  };

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <MainLayout>
      <Container>

        <Hero1
          title={mainPage?.title}
          subtitle={mainPage?.subtitle}
          buttonText={mainPage?.button_text}
          video={mainPage?.video}
          icons={mainPage?.background_image}
        />
        <SliderCourses data={courses} />
        <Benefits data={mainPage?.benefits}></Benefits>
        <ChooseUs
          title={mainPage?.choose_us_title}
          imageUs={mainPage?.choose_us_image}
          certificationImages={mainPage?.certification_images}
          Imgdescription={mainPage?.choose_us_image_description}
          chooseUsContent={mainPage?.choose_us_content}
          counts={mainPage?.counts}
          phrase={mainPage?.choose_us_phrase}
        />
        <Testimonys 
          data={testimonies}
          main={mainPage}
          />

        <Modalstyled
        isOpen={isOpen}
        style={customStyles}
        onRequestClose={closeModal}
        contentLabel="Flyer"
      >
        
            <FlyerContainer>

              <Flyer src={mainPage?.flyer_image}></Flyer>
              

            </FlyerContainer>
            <Buttonsmall onClick={closeModal}>X</Buttonsmall>
            <GoCourses to="/programacion/cursos-disponibles"><img src="/images/Courses/modalgocourses.svg" /></GoCourses>
        
      </Modalstyled>

      </Container>
    </MainLayout>
  )
}

export default IndexPage
